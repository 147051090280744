const mergeAndSortChangelogAndReleases = (releases, allChangelog) => {

  // Transform releases data structure into a list of <aep>.<amp> that can
  // be woven into the changelog articles
  let reduced = []
  releases.forEach(release => {
    reduced = release.data.edges.reduce((acc, curr) => {
      curr.node.amp.forEach((amp) => acc.push({ node: { aep: curr.node.aep, amp: amp, major: release.major, version: curr.node.aep.version + '.' + amp.version}}))
      return acc;
    }, reduced)
  })


  // Combine all data points
  const combinedEdges = [ ...allChangelog.edges, ...reduced ]

  // Sort by publication date descending
  combinedEdges.sort((left, right) => {
    const leftDate = left.node.frontmatter?.modified || left.node.amp.released
    const rightDate = right.node.frontmatter?.modified || right.node.amp.released

    if (leftDate < rightDate) {
      return 1;
    }
    if (leftDate > rightDate) {
      return -1;
    }
    return 0;
  })

  return combinedEdges

}

const sortByTitle = (allChangelog) => {
  const combinedEdges= allChangelog
  combinedEdges.sort((left, right) => {
    const leftDate = left.node.frontmatter?.title
    const rightDate = right.node.frontmatter?.title

    if (leftDate < rightDate) {
      return 1;
    }
    if (leftDate > rightDate) {
      return -1;
    }
    return 0;
  })

  return combinedEdges
}

const getMajorVersion = (version) => {
  if (version < 2110) {
    return 10;
  } else
    return 11
}

const releaseToTitle = (aep, amp) => `CoreMedia Content Cloud ${aep.version}.${amp.version}`

module.exports = { mergeAndSortChangelogAndReleases, releaseToTitle, getMajorVersion,sortByTitle }
