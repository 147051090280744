import React from 'react'
import { graphql } from 'gatsby'
import ContainerWrapper from '../../../components/ContainerWrapper'
import { Col, Row } from 'react-grid-system'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import dayjs from 'dayjs'
import styled from 'styled-components'
import usePrism from '../../../hooks/use-prism'
import Seo from '../../../components/SEO'
import { Link2 } from 'react-feather'
import { headlineToId } from '../../../utils/links'
import { scroller } from 'react-scroll'
import { sortByTitle} from '../../../utils/changelog'
import { useLocation } from '@gatsbyjs/reach-router'
import Tag from '../../../components/Tag'
import { useEffect, useState} from "react"

const LogArticle = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 1rem;
    a, a:visited {
      display: flex;
      align-items: center;
      color: #3f3f3f;
      text-decoration: none;
      font-weight: bold;

      svg {
        margin-left: 1rem;
        color: #CCCCCC;
      }
    }

    a:hover {
      content: '';
      text-decoration: underline;
      color: #3f3f3f;
      cursor: pointer;
      font-weight: bold;

      svg {
        color: #3f3f3f;
      }
    }
  }
`

const EntryContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1380px) {
    flex-direction: column;
  }
`

const LeftDiv = styled.div`
  flex: 0 0 40%;
  margin-right: 1rem;

  @media (max-width: 1000px) {
    flex: 0 0 100%;
    margin-right: 0;
  }
`

const RightDiv = styled.div`
  flex: 0 0 60%;

  summary {
    list-style: none;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    margin-bottom: 1rem;
    font-size: 100%;
    font-weight: bold;
  }

  summary::before {
    content: '\\25B6';
    position: absolute;
    left: 0;
    top: 25%;
    font-size: 0.8em;
    line-height: 1.0em;
    transition: transform 0.2s ease-in-out;
  }

  details[open] summary::before {
    content: '\\25BC';
  }

  @media (max-width: 1000px) {
    flex: 0 0 100%;
  }
`
const Style = styled.div`
  .log {
    > div {
    }
  }

  p {
    font-size: 18px;
  }

  .meta {
    font-size: 14px;
    text-transform: uppercase;

    .date {
      margin-bottom: 24px;
      color: #787878;
      line-height: 150%;
    }

    .chips {
      margin-bottom: 12px;

      > div {
        display: inline-block;
        color: #ffffff;
        background-color: #006cae;
        border-radius: 24px;
        padding: 1px 12px;
      }

      > div + div {
        margin-left: 6px;
      }
    }
  }

  .rss-link {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    a {
      margin-left: 6px;
      text-decoration: underline;
      color: #006cae;
    }
  }

  .article {
    h2 {
      margin-top: 1rem;
      a, a:visited {
        display: flex;
        align-items: center;
        color: #3f3f3f;
        text-decoration: none;
        font-weight: bold;

        svg {
          margin-left: 1rem;
          color: #CCCCCC;
        }
      }

      a:hover {
        content: '';
        text-decoration: none;
        color: #3f3f3f;
        cursor: pointer;

        svg {
          color: #3f3f3f;
        }
      }
    }
  }

  .filter {
    margin-top: 0px;
    margin-bottom: 1rem;
    word-wrap: normal;
  }
`

const scrollerConfig = { smooth: true, offset: -130 }

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 2,
      margin: "15px 0px 0px 0px",
    }}
  />
)

const WhatsNewEntry = ({ node }) => {
  const anchor = headlineToId(node.frontmatter.title)
  const container = React.useRef()
  const releaseString = node.frontmatter.abstract
  const datePart = releaseString.split(" ")[2]
  console.log("DAte Part is: ", datePart);
  const date = dayjs(datePart).format('D. MMMM, YYYY')
  console.log("date", date)
  usePrism(container)
  return (
    <div>
      <EntryContainer ref={container}>
        <LeftDiv>
          <h2 id={anchor} style={{ display: 'flex', alignItems: 'center' }}>
            <a href={`#${anchor}`} onClick={() => scroller.scrollTo(anchor, scrollerConfig)}>
              {node.frontmatter.title}
              <Link2 style={{ marginLeft: '0.5rem', flexShrink: 0 }} />
            </a>
          </h2>
          <div className={'meta'}>
            <div>
              {node.frontmatter.cloud &&
                <Tag tagValue={node.frontmatter.cloud} fontSize={"100%"} backgroundColor={'#6FC3B8'} />}
              {node.frontmatter.version &&
                <Tag tagValue={node.frontmatter.version} fontSize={"100%"} backgroundColor={'#6FC3B8'} />}
              {node.frontmatter.component &&
                <Tag tagValue={node.frontmatter.component} fontSize={"100%"} backgroundColor={'#6FC3B8'} />}
            </div>
            <div className={'date'}>
              {date}
            </div>
          </div>
        </LeftDiv>
        <RightDiv>
          <div style={{ marginTop: '1rem' }}>
            <MDXRenderer>{node.body}</MDXRenderer>
          </div>
        </RightDiv>
      </EntryContainer>
      <ColoredLine color="#CCC" />
    </div>
  );
}

const Child = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.substr(1), scrollerConfig)
    }
  }, [location.hash])
  return ''
}


const ReleaseNotes = ({ pageContext, data }) => {

  const [showChild, setShowChild] = useState(false)

  useEffect(() => {
    setShowChild(true)
  }, [])
  try {
    const combinedEdges = sortByTitle(data.allMdx.edges)

    const {
      breadcrumb: { crumbs },
    } = pageContext


    return (
      <ContainerWrapper crumbs={crumbs} fluid={true}>
        <Seo
          title={`Engagement Cloud Release Notes`}
          description={'Overview of all releases of the Engagement Cloud'}
        />
        <Style>
          <Row>

            <Col xs={12} md={8}>
              <h1>Release Notes of the Engagement Cloud</h1>
              <p></p>
              {/* <p>
                Find here the main changes and improvements in every release of the Engagement Cloud.
                The release notes are sorted by release version.
              </p> */}
              <ColoredLine color="#CCC" />
              <LogArticle>
                {combinedEdges.map(({ node }, index) => {
                  return <WhatsNewEntry node={node} key={index} />
                })};
              </LogArticle>
              {showChild && <Child />}
            </Col>
          </Row>
        </Style>
      </ContainerWrapper>
    )
  } catch (error) {
    console.error("Error rendering ReleaseNotes component:", error)
    return <div>Error loading release notes. Please try again later.</div>
  }
}

export const Head = () =>
  <link rel="alternate" type="application/rss+xml"
        title="CoreMedia Content Cloud - What's New RSS Feed"
        href="/whats-new-rss.xml" />

export default ReleaseNotes

export const query = graphql`
  {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/.*\\/release-notes-ec\\/.*/"}}
      sort: {fields: frontmatter___modified, order: DESC}
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            abstract
            modified
            component
            version
            cloud
            majorVersion
            releaseDate
          }
        }
      }
    }
  }
`
