import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#006CAE')};
  color: #ffffff;
  font-family: ${(props) => props.font};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '80%')};
  padding: 0 12px 6px 12px;
  position: relative;
  margin: 1rem 0.5rem;
  display: inline-block;
  margin-left: 3px;

  @media screen and (min-width: 768px) {
    top: -.3rem;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 8px;
  }

  ::before {
    content: '';
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
    border: 6px solid transparent;
    border-top-width: 0;
    border-bottom-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#006CAE')};
    border-right: none;
    top: -6px;
    box-sizing: border-box;
  }
`;

/**
 * Label used mainly in the banner to mark a distribution as latest.
 *
 * @param tagValue
 * @returns {*}
 * @constructor
 */
const Tag = ({tagValue, fontSize, backgroundColor}) => {
  const font = '"DM Sans", sans-serif';

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      color="#ffffff"
      font={font}
      fontSize={fontSize}
      top="-.3rem"
    >
      {tagValue}
    </Wrapper>
  );
};

Tag.propTypes = {
  /** Content of the label */
  tagValue: PropTypes.node.isRequired,
  fontSize: PropTypes.node,
  backgroundColor: PropTypes.node
};

export default Tag;
